import { Dispatch } from 'redux'
import { addExistingPlan, addNewPlan } from '../../../apis/planApi'
import { Action, ActionType, DogType } from '../../../types/dogType'

export const createDogs = (dogInfo: DogType) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.ADD_ACCOUNT_DOGS_PENDING
    })
    try {
      const success = await addNewPlan(dogInfo)
      for (let i = dogInfo.dogs.length - 1; i < 5; i++) {
        dogInfo.dogs.push({
          name: '',
          weight: 0,
          dob: '',
          exercise: '',
          physique: '',
          neutered: '',
          gender: '',
          breed: '',
          ageStage: ''
        })
      }
      dispatch({
        type: ActionType.ADD_ACCOUNT_DOGS_SUCCESS,
        payload: {
          dogInfo,
          summary: success.dogs,
          pack: success.defaultPackSize,
          packSizes: success.packSizes,
          defaultPack: success.defaultPackSize.default,
          shippingDates: success.shippingDates
        }
      })
    } catch (_err) {
      dispatch({
        type: ActionType.ADD_ACCOUNT_DOGS_FAIL
      })
    }
  }
}

export const createDogsExistingPlan = (dogInfo: any) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.ADD_ACCOUNT_DOGS_PENDING
    })
    try {
      const success = await addExistingPlan(dogInfo)
      for (let i = dogInfo.dogs.length - 1; i < 5; i++) {
        dogInfo.dogs.push({
          name: '',
          weight: 0,
          dob: '',
          exercise: '',
          physique: '',
          neutered: '',
          gender: '',
          breed: '',
          ageStage: ''
        })
      }
      dispatch({
        type: ActionType.ADD_ACCOUNT_DOGS_SUCCESS,
        payload: {
          dogInfo: success,
          summary: success.dogs,
          pack: success.defaultPackSize,
          packSizes: success.packSizes,
          defaultPack: success.defaultPackSize.default,
          shippingDates: success.shippingDates
        }
      })
    } catch (_err) {
      dispatch({
        type: ActionType.ADD_ACCOUNT_DOGS_FAIL
      })
    }
  }
}

export const resetErrorMessage = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.RESET_ERROR_MESSAGE
    })
  }
}

export const updateChangedStatus = (status: boolean) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.UPDATE_ACCOUNT_CHANGE_STATUS,
      payload: status
    })
  }
}

export const updateAddonDiscount = (discount: number) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.UPDATE_ADDON_DISCOUNT,
      payload: discount
    })
  }
}

export const resetFlowDogs = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.RESET
    })
  }
}
