import { navigate } from 'gatsby'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { resetDogs } from 'state/actions/account/accountDogAction'
import { resetPlansAndOrders } from 'state/actions/account/accountPlanAction'
import { resetFlowDogs } from 'state/actions/account/flow/dogAction'
import { resetFlowPlan } from 'state/actions/account/flow/planAction'
import { resetFlowShipping } from 'state/actions/account/flow/shippingAction'
import { resetAccountSummary } from 'state/actions/accountSummary'
import { updateSpoofMode } from 'state/actions/uiAction'
import Api from 'state/apis/api'

const Admin = () => {
  const [userId, setUserId] = useState('')
  const [iframeUrl, setIframeUrl] = useState('')

  const dispatch = useDispatch()

  const callMetabase = () => {
    const url = '/v1/private/admin/user/dashboard/2'
    Api.get('wwwREST', url, {})
      .then((res: any) => {
        setIframeUrl(res.dashboardUrl)
      })
      .catch((err: any) => {
        console.log(err)
      })
  }

  useEffect(() => {
    callMetabase()
  }, [])

  const handleSpoofUser = () => {
    localStorage.removeItem('persist:root')
    dispatch(resetAccountSummary())
    dispatch(resetDogs())
    dispatch(resetPlansAndOrders())
    dispatch(resetFlowDogs())
    dispatch(resetFlowPlan())
    dispatch(resetFlowShipping())
    dispatch(updateSpoofMode(true, userId, ''))

    navigate('/account?userId=' + userId)
  }

  return (
    <>
      <div className="mb-12" style={{ width: '400px' }}>
        <div className="font-semibold">Spoof a user:</div>
        <input className="mt-5" type="text" placeholder="Enter user id" onChange={e => setUserId(e.target.value)} />

        <div>
          <button
            type="button"
            className="mt-5 flex justify-center rounded-md border border-transparent bg-primary py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-base focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            onClick={handleSpoofUser}
          >
            Spoof
          </button>
        </div>
      </div>
      <iframe src={iframeUrl} frameborder="0" width="800" height="1600" allowtransparency></iframe>
    </>
  )
}

export default Admin
